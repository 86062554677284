.App {
    min-height: 100vh;
    background-color: #8a6fbc;
}

.navbar {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    height: 60px;
    background-color: #8637da;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-logo {
    margin-left: 20px;
    font-size: 25px;
    font-weight: 700;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
}

.nav-buttons {
    margin-right: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.link-btn {
    height: 40px;
    width: 107px;
    background-color: rgba(94, 35, 157, 0.79);
    border: none;
    border-radius: 52px;
    text-transform: capitalize;
    text-decoration: none;
    font-size: 13px;
    font-weight: 700;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

footer {
    height: 300px;
    margin-top: 50px;
    background-color: #000;
}
