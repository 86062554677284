.about-us {
    animation: fadeIn 0.5s ease-in;
}

.about-us-title {
    margin-top: 50px;
    margin-left: 40px;
    color: #fff;
}

.about-us-text {
    width: 50%;
    margin-left: 80px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: white;
}

.meet-the-team {
    margin-top: 80px;
    margin-bottom: 200px;
}

.meet-the-team-title {
    text-align: center;
    color: #fff;
}

.team-members {
    position: relative;
    margin-top: 120px;
    height: 250px;
    background-color: #420263;
}

.first-row-team {
    position: absolute;
    width: 100%;
    top: -30%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.second-row-team {
    position: absolute;
    width: 100%;
    top: 70%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
