.team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.team-member-picture-wrapper {
    height: 150px;
    width: 150px;
    background-color: #5b038b;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.team-member-picture {
    height: 130px;
    width: 130px;
    background-color: white;
    border-radius: 50%;
}

.team-member-name {
    width: 80px;
    font-size: 20px;
    color: #fff;
    text-align: center;
}
