.home {
    animation: fadeIn 0.5s ease-in;
}

.what-we-can-do {
    width: 50%;
}

.what-we-can-do-title {
    margin-top: 50px;
    margin-left: 40px;
    color: #fff;
}

.what-we-can-do-text {
    margin-left: 80px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: white;
}

.vision-wrapper {
    display: flex;
    justify-content: space-between;
}

.vision-do {
    width: 50%;
    margin-right: 40px;
}

.vision-title {
    margin-top: 50px;
    color: #fff;
}

.vision-text {
    padding-left: 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: white;
}

.app-demo-title {
    margin-top: 50px;
    margin-left: 40px;
    margin-bottom: 40px;
    color: #fff;
}

.app-demo-content {
    height: 200px;
    width: 80%;
    margin-left: 80px;
    background-color: #fff;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
